import { Button as DesignSystemButton, ButtonProps } from '@loveholidays/design-system';
import React, { forwardRef } from 'react';

export const Button = forwardRef<any, ButtonProps>((props: ButtonProps, ref) => {
  // Use `PrimaryLegacy` (green) variant for Primary buttons when `YellowButton` is off
  const variant = props.variant === 'Primary' ? 'PrimaryLegacy' : props.variant;

  return (
    <DesignSystemButton
      ref={ref}
      {...props}
      variant={variant}
    />
  );
});
